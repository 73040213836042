<template>
	<div class="row cf-cnt">
		<div class="col-lg-3 col-md-4 col-sm-6">
			<ChooseFormatItem title="Embed Code"
							  description="Embed the SEO audit tool."
							  type="embed-code"
							  @buttonClick="goToNextStep('embed', 'Embed Code')">

			</ChooseFormatItem>

		</div>
		<div class="col-lg-3 col-md-4 col-sm-6">
			<ChooseFormatItem title="Pop up Code"
							  description="Capture leads with a pop up."
							  type="popup-code"
							  @buttonClick="goToNextStep('popup', 'Pop up Code')">

			</ChooseFormatItem>

		</div>
		<div class="col-lg-3 col-md-4 col-sm-6">
			<ChooseFormatItem title="Landing Page"
							  description="Dedicate landing page with a form."
							 type="landing-page"
							  @buttonClick="goToNextStep('landing', 'Landing Page')">

			</ChooseFormatItem>
		</div>


	</div>
</template>

<script>
    import ChooseFormatItem from "./components/ChooseFormatItem";

    export default {
        name: "ChooseFormat",
        components: {ChooseFormatItem},
        methods: {
            goToNextStep(type, title) {
                this.$store.dispatch("setupForm/setChosenFormat", {
                    type: type,
                    title: title,
				});

                this.$nextTick(() => {
                    this.$router.push({name: 'dashboard.setup-form.build-form'})
                });
            },

        },

    }
</script>
