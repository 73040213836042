<template>
	<div class="card choose-format-item">
		<img class="card-img-top" :src="require('@shared/assets/styles/images/' + type + '.svg')" :alt="type">
		<div class="card-body">
			<h5 class="card-title">{{title}}</h5>
			<p class="card-text">{{description}}</p>
			<button @click='buttonClick' class="btn btn-primary">Get Started</button>
		</div>
	</div>
</template>

<script>
    export default {
        name: "chooseFormatItem",
		props: {
            title: {
                type: String
			},
			description: {
                type: String
			},
			type : {
                type: String
			},
			route: {
                type: Object
			},
		},
		methods: {
            buttonClick() {
                this.$emit('buttonClick')
			}
		}

    }
</script>
